import React from 'react'
import { Card } from "react-bootstrap"
const ShortStoryText = ({text}) => {
    return (
      <Card.Text style={{ marginBottom: "0px" }} className="anti-copy">
        {text}
      </Card.Text>
    )
}

export default ShortStoryText
