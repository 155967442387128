import React from 'react'
import { Card, Row, Col, Container } from "react-bootstrap"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getImages = graphql`
  {
    fluid: file(relativePath: { eq: "SStoryFront.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    back: file(relativePath: { eq: "SStoryBack.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
const ShortStoryBook = () => {
  const data = useStaticQuery(getImages)
    return (
      <Container>
        <Row>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            style={{ paddingLeft: "0px" }}
          >
            <Image
              fluid={data.back.childImageSharp.fluid}
              alt={"Front Cover"}
              style={{ height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            style={{ paddingLeft: "0px" }}
          >
            <Image
              fluid={data.fluid.childImageSharp.fluid}
              alt={"Back Cover"}
              style={{ height: "100%", width: "100%" }}
              imgStyle={{ objectFit: "contain" }}
            />
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            style={{ paddingRight: "0px" }}
          >
            <Card.Title>In Any Shape or Form - Pamela Mary Brown</Card.Title>
            <Card.Text style={{ marginBottom: "12px" }} className="anti-copy">
              'In Any Shape or Form' references a quotation from James Joyce on
              the theme of violence. Violence is the common denominator in this
              collection of stories where characterisation is central. A couple
              in a restaurant unravel into aggression. A family after the death
              of parents create a domestic hell. A father decides his infant
              daughter's future. A homeless alcoholic dies after a violent
              confrontation. A woman loses her only friend to cancer. A prisoner
              narrates to his cell mate about violence. A woman visits her son
              who is beginning his time inside. Dead birds represent victims of
              domestic abuse. A criminal on bail evades the violence of court
              protocol. A child custody case ends in violence. A new mother
              lives with her sadistically homicidal partner. A woman cannot
              escape domestic violence, abuse and slavery. A mother suppresses
              her daughter’s sexuality. The prescribed fate in these lives is
              fall out. Some outcomes are fatal where humans and violence are
              enmeshed. Survival is the least option. These stories are a user's
              manual in extremity.
            </Card.Text>
            <Card.Text>
              Purchase:{" "}
              <a
                href="https://www.amazon.co.uk/Shape-Form-Pamela-Mary-Brown/dp/1548848689/ref=sr_1_1?dchild=1&keywords=In+Any+Shape+or+Form+Pamela+Mary+Brown&qid=1621952392&sr=8-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                In Any Shape or Form - Pamela Mary Brown
              </a>
            </Card.Text>
          </Col>
        </Row>
      </Container>
    )
}

export default ShortStoryBook
