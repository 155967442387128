import React from "react"
import { graphql } from "gatsby"
import { Card } from "react-bootstrap"
import Layout from "../components/layout"
import ShortStoryBook from "../components/ShortStoryBook"
import ShortStoryText from "../components/ShortStoryText"
import { Helmet } from "react-helmet"
export const getShortStory = graphql`
  {
    contentfulShortStory {
      link
      shortStoryTitle
      story {
        story
      }
      shortStoryBook {
        fluid {
          ...GatsbyContentfulFluid
        }
        title
      }
    }
  }
`

const shortStoryText =
  "Agnes felt the invariable Wednesday night nausea and her stomach contracted." +
  "Here it comes, here comes the night...and she turned up the cuffs on her woollen cardigan, and she pushed her hands deep into the pockets, and she coughed," +
  "and she braced herself for the routine confrontation with her husband and teenage son.$" +
  "\n" +
  "In the sitting room, they were splayed lethargically on the sofa; their feet elevated on twin pouffes. Stained dinner plates, used cutlery and half-drunk glasses of milk were strewn on the floor.$" +
  "\n" +
  "‘It’s rubbish night,’ her voice was an undertone to the blaring television.$" +
  "\n" +
  "‘Give me that bloody thing!’ Agnes’s gruff husband grabbed the TV remote control from his son and tightened his lips as he turned up the volume.$" +
  "\n" +
  "‘It’s Wednesday night, someone needs to put the bin out.’ She made the usual petition.$" +
  "\n" +
  "‘I’ve just this minute sat down...he needs to move his fat arse...do something to earn his keep.’ Her husband spoke and waved the remote control in the air.$" +
  "\n" +
  "‘I’ve been following this TV series since the very first episode...I can’t miss this one, Dad...’$" +
  "\n" +
  "Her son snatched the remote control once again and looking at his father he screwed his face up. His father reacted by returning a snide look. Their faces shared idiosyncratic snarls.$" +
  "\n" +
  "Agnes retreated from the sitting room, shutting the door quietly. She’d asked them for help and they’d refused, that was standard practice, but when she didn’t ask for help with a chore, and commented about it later, they would berate her for not asking. Their bickering continued to be audible from the sitting room and the outcome was inevitable; Agnes had to put the rubbish bin out for collection.$" +
  "\n" +
  "The backyard light was faint and flickered inside a metal cage. The bulb would soon have to be replaced. The yard was small, and the terrace house built on a slope in the late 18th century. The row had uniform fronts and Agnes’ house was mid-terrace. The back of the houses lacked light due to the high retaining wall, and the small concrete backyard would have been put to better use as an artificial climbing wall for mountaineering enthusiasts.$" +
  "\n" +
  "Agnes cautiously mounted the steep steps to open the back-gate which sagged on its hinges.$" +
  "\n" +
  "The gate creaked as she endeavoured to heave it upwards. It scraped along the concrete but Agnes had devised a method to manoeuvre it open just enough to accommodate the width of the bin. It had been a tiring day, and this was the last of the chores before bedtime.$" +
  "\n" +
  "‘Maybe I’ll peel the potatoes for tomorrow’s dinner,’ Agnes muttered. ‘I could put a pile of dirty clothes into the washing machine...hang them on the clothesline first thing in the morning.’$" +
  "\n" +
  "Twelve steps to the gate Agnes thought as she returned to the backdoor of the house and gripped the handles of the wheelie bin, her back to the climb.$" +
  "\n" +
  "Step one, and she heaved and the weight of the bin increased her heart-rate and she thought about how when she first moved into the house an elderly neighbour helped her put the bin out each week. He had died of lung cancer a few years earlier. The last occasion she saw him he told her that his biggest regret was letting the surgeons cut him open.$" +
  "\n" +
  "Step two, and she thought about how her husband would drive over town to his mother’s house on a Tuesday night to put her rubbish bin out for collection.$" +
  "\n" +
  "Step three, and she paused to make sure the load was tilted correctly and her balance accurately measured.$" +
  "\n" +
  "Step four, and she thought about never having planted a garden and how mature it would have been if she’d thrown in a few seeds or added a hanging basket.$" +
  "\n" +
  "Step five, and she thought about how life just seemed to be an uphill struggle.$" +
  "\n" +
  "Step six, and Agnes had to move her shoulders in counter-clockwise circles to ease the tension in her stressed muscles.$" +
  "\n" +
  "Step seven, and she wondered if her life would have been any different if she’d had a daughter, or a best friend or a sister.$" +
  "\n" +
  "Step eight, and Agnes thought about how frightened she was when leaving the house, but she had to get groceries at the supermarket. Her husband and teenage son never shopped for food, yet they stood by the open fridge door asking for milk, or eggs, or sausages.$" +
  "\n" +
  "Step nine and Agnes stopped, and she sighed, and she thought about what the Feminist Movement might think of her, standing on this very step, with the heavy rubbish bin. They believed housework to be unpaid domestic labour. Imagine!$" +
  "\n" +
  "Step ten, and Agnes gripped the handle tighter and heaved the rubbish bin upwards with all the strength she could muster.$" +
  "\n" +
  "‘Almost there, Agnes,’ she whispered softly. ‘You’re almost there.’$" +
  "\n" +
  "Step eleven, and she thought about her great aunt’s reply to her saying that if there was such a thing as reincarnation she would come back as a man.$" +
  "\n" +
  "‘Why Agnes, are you afraid of work!’$" +
  "\n" +
  "Step twelve, and she thought about her triumph having made it to the top step and how another Wednesday would pass and she hadn’t suffered a broken hip from a fall.$" +
  "\n" +
  "Agnes dragged the bin down the gloomy alleyway behind the row of houses. It was narrow but habit made the journey navigable. She placed the bin perfectly into a slot among the line-up of neighbours’ bins awaiting collection. The bins were lined up numerically and in ascending order.$" +
  "\n" +
  "Returning to the house Agnes thought about how she thought about the same things every Wednesday night, week in, week out, over and over.$" +
  "\n" +
  "Agnes locked the sagging gate with the hinges creaking and walking down the steps she thought about how she would ask her husband and teenage son to bring in the empty bin the following morning. Neither of them would do it.$" +
  "\n" +
  "‘Oh, to hell with it. The task will be much easier tomorrow,’ Agnes said to herself as she closed the door and flipped the switch leaving the backyard in darkness. ‘The bin’s always lighter when the rubbish has been dumped out.’$"

const shortStory = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Short Story - Pamela Mary Brown</title>
      </Helmet>
      <h2>Short Story</h2>
      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <ShortStoryBook />
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "20px" }} className="anti-copy">
          Rubbish Night or Agnes contemplates the Feminist Movement,
          Reincarnation and Universal Harmony while putting out the household
          rubbish by Pamela Mary Brown
        </Card.Title>

        {shortStoryText.split("$").map((paragraph, index) => {
          return <ShortStoryText text={paragraph} key={index} />
        })}
      </Card>
    </Layout>
  )
}

export default shortStory
